<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :revert="refreshDocument"
      :save="saveDocument"
      :delete-document="deleteDocument"
      :show-delete="true"
      close-after-save
      v-on="$listeners"
      no-history
    />
    <document-in-tab>
      <div v-show="!document.id">
        <v-skeleton-loader
          class="identification"
          height=480
          type="image"
        />
      </div>
      <div v-show="document.id">
        <v-form
          ref="form"
          v-model="form"
        >
          <v-card>
            <v-card-title>
              {{$t('t.Details')}}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <div class="d-flex flex-column justify-space-between">
                    <date-picker-menu
                      class="mr-4"
                      :label="$t('t.TargetDate')"
                      :min="moment().format('YYYY-MM-DD')"
                      :rules="required"
                      v-model="document.targetDate"
                    />
                    <picker
                      class="mb-2"
                      :rules="required"
                      :label="$t('t.WorkItemType')"
                      :disabled="false"
                      :clearable="true"
                      :selected-id.sync="document.workItemTypeId"
                      work-item-type-is-account-action
                      document-types="work-item-types"
                    />
                    <user-picker
                      class="mb-2"
                      :rules="required"
                      :selection.sync="user"
                      :scope="accountScope"
                      document-types="users"
                      :can-close-chip="true"
                      :placeholder="$t('t.User')"
                    >
                      <template v-slot:item="{parent, item}">
                        <v-list-item-icon>
                          <v-icon>{{item.group ? $icon('i.Users') : $icon('i.User')}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-html="parent.genFilteredText(`${item.fullname} (${item.group ? item.group.name : 'Contact'})`)" />
                        </v-list-item-content>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>{{$t('t.NoResult')}}</v-list-item-content>
                        </v-list-item>
                      </template>
                    </user-picker>
                    <v-select
                      :rules="priority_required"
                      :items="priorities"
                      item-text="text"
                      item-value="id"
                      :label="$t('t.Priority')"
                      v-model="priority"
                    />
                    <v-switch
                      v-if="allowFollowUpToBlockProtocol"
                      :disabled="isBlockingProtocolLocked"
                      v-model="document.isBlockingProtocol"
                      :label="$t('t.AllowDocumentToBlockTheProtocol')"
                    />
                  </div>
                </v-col>
                <v-col>
                  <v-card class="mt-n16 elevation-0">
                    <v-card-title>
                      {{$t('t.Comment')}}
                    </v-card-title>
                    <v-card-text>
                      <v-textarea
                        class="comment"
                        v-model="document.comment"
                        rows="15"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </div>
    </document-in-tab>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'
import recentsController from '@/recentsController'
import Scope from '@/pages/search/controllers/scope'

export default {
  mixins: [DocumentEditor],
  components: {
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    UserPicker: () => import('@/components/user-picker'),
    Picker: () => import('@/components/picker')
  },
  async created () {
    await this.loadRefData()
    Object.assign(this.tab, { cancel: this.cancel })
  },
  data () {
    return {
      accountScope: Scope.global(),
      allowFollowUpToBlockProtocol: false,
      isBlockingProtocolLocked: false,
      priorities: [],
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      priority_required: [v => v !== null || this.$t('t.IsRequired')]
    }
  },
  computed: {
    priority: {
      get () {
        return this.document.priority
      },
      set (id) {
        this.document = Object.assign({}, this.document, { priority: id })
      }
    },
    user: {
      get () {
        if (this.document.userId) {
          return { id: this.document.userId }
        }

        return null
      },
      set (u) {
        this.document = Object.assign({}, this.document, { userId: u?.id })
      }
    },

    form: {
      get () {
        return false
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    },
    cacheType () {
      return DocumentEditor.CacheType.WorkItemTemplateDetail
    },
    id () {
      return this.tab.documentId
    }
  },
  methods: {
    async loadRefData () {
      const [
        wipr,
        fusr
      ] = await Promise.all([
        this.$http().get('/core/v6/work-item-priorities'),
        this.$http().get('/core/v6/settings/follow-up')
      ])
      this.priorities = wipr.data
      this.allowFollowUpToBlockProtocol = fusr.data.allowFollowUpToBlockProtocol
      this.document.isBlockingProtocol = fusr.data.isBlockingProtocol
      this.isBlockingProtocolLocked = fusr.data.isBlockingProtocolLocked
    },
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign({}, this.document, {
          id: this.tab.documentId,
          accountId: this.$route.params.id,
          workItemType: null,
          targetDate: this.moment().add(1, 'day').toISOString(),
          user: this.$store.getters.currentUser,
          priority: 1,
          comment: null
        })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }
      }
      this.accountScope = Scope.account(this.document.accountId)
      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    },
    documentToJSON () {
      return {
        id: this.document.id,
        accountId: this.document.accountId,
        isBlockingProtocol: this.document.isBlockingProtocol,
        priority: this.document.priority,
        targetDate: this.moment(this.document.targetDate).format('YYYY-MM-DD'),
        userId: this.document.userId,
        workItemTypeId: this.document.workItemTypeId,
        comment: this.document.comment
      }
    },
    async save () {
      if (this.documentIsNew) {
        return this.$http().post('/core/v6/work-item-templates', this.documentToJSON())
      } else {
        return this.$http().put(`/core/v6/work-item-templates/${this.document.id}`, this.documentToJSON())
      }
    },
    async deleteDocument () {
      await this.$http().delete(`/core/v6/work-item-templates/${this.document.id}`)
        .then(() => recentsController.cancel(this.tab))
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.v-card, .v-skeleton-loader
  margin 0.5em

.work-item-type
  width 400px

.comment
  width 400px
  border solid 1px var(--bg-plain)
  border-radius var(--border-radius)

.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.v-text-field, .v-input--switch
  margin-right 1em
</style>
